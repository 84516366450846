import BaseService from "./BaseService";

class AgencyService extends BaseService {

    async getAll(req) {
        const response = await this.axios.post('/agency/all', req);
        return await response.data;
    }

    async getDetails(req) {
        const response = await this.axios.post('/agency/details', req);
        return await response.data;
    }
    async updateAgencyDetails(req) {
        const response = await this.axios.post('/agency/details/update', req);
        return response.data;
    }

    async deleteAgency(req) {
        const response = await this.axios.post('/agency/delete', req);
        return response.data;
    }

    async blockAgency(req) {
        const response = await this.axios.post('/agency/block', req);
        return response.data;
    }

    async getAgencyPercentages(req) {
        const response = await this.axios.post('/agency/percentages', req);
        return response.data;
    }
    async updateAgencyPercentages(req) {
        const response = await this.axios.post('/agency/percentages/update', req);
        return response.data;
    }

    async getPaymentProfile(req) {
        const response = await this.axios.post('/agency/payment_profile', req);
        return response.data;
    }

    async getAgencyInstallData(req) {
        const response = await this.axios.post('/agency/install', req);
        return response.data;
    }

    async createAgency(req) {
        const response = await this.axios.post('/agency/create', req);
        return response.data;
    }

    async fecthAgencyId(req) {
        const response = await this.axios.post('/agency/fetch_id', req);
        return response.data;
    }

    async getUsers(req) {
        const response = await this.axios.post('/agency/user/all', req);
        return await response.data;
    }

    async createUser(req) {
        const response = await this.axios.post('/agency/user/create', req);
        return await response.data;
    }
    async updateUser(req) {
        const response = await this.axios.post('/agency/user/update', req);
        return await response.data;
    }

    async deleteUser(req) {
        const response = await this.axios.post('/agency/user/delete', req);
        return await response.data;
    }

    async blockUser(req) {
        const response = await this.axios.post('/agency/user/block', req);
        return await response.data;
    }
}

export default AgencyService
